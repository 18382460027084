import React, { useState } from "react"
import { graphql } from "gatsby"
import { useFormik } from "formik"
import { object, string } from "yup"

import FormGroup from "../components/form-group"
import Input from "../components/input"
import Layout from "../components/layout"
import SEO from "../components/seo"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactPage = ({ data }) => {
  const { Title, Slug } = data.strapiPages

  const [success, setSuccess] = useState(false)

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    validationSchema: object({
      name: string().required(),
      subject: string().required(),
      email: string()
        .email()
        .required(),
      message: string()
        .min(20)
        .required(),
    }),
    onSubmit: (values, actions) => {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...values }),
      })
        .then(() => {
          setSuccess(true)
          actions.resetForm()
        })
        .finally(() => actions.setSubmitting(false))
    },
  })

  return (
    <Layout>
      <SEO title={Title} />
      <section
        className="resume-section p-3 p-lg-5 d-flex justify-content-top"
        id={Slug}
      >
        <div className="w-100">
          <h2 className="mb-5 h1">{Title}</h2>
          {!success && (
            <form
              name="contact"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={formik.handleSubmit}
            >
              <input type="hidden" name="form-name" value="contact" />
              <Input name="name" label="Name" formik={formik} />
              <Input name="email" label="Email" type="email" formik={formik} />
              <Input name="subject" label="Subject" formik={formik} />
              <FormGroup name="message" label="Message" formik={formik}>
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  placeholder="Hi Leo, "
                  rows={4}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                ></textarea>
              </FormGroup>

              <button type="submit" className="btn btn-dark">
                Submit
              </button>
            </form>
          )}

          {success && (
            <div className="p-2 alert alert-success" role="alert">
              Thank you for your message. It has been sent.
            </div>
          )}
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query ContactQuery {
    strapiPages(Slug: { eq: "contact" }) {
      Title
      Slug
    }
  }
`

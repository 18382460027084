import React from "react"
import FormGroup from "./form-group"

const Input = props => {
  const { formik, name } = props

  return (
    <FormGroup {...props}>
      <input
        type={props.type || "text"}
        className="form-control"
        name={name}
        id={name}
        placeholder={`Enter ${name}`}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values[name]}
      />
    </FormGroup>
  )
}

export default Input

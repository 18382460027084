import React from "react"

const FormGroup = ({ children, label, name, formik, ...props }) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      {children}
      {formik.touched[name] && formik.errors[name] ? (
        <div className="p-1 text-danger">{formik.errors[name]}</div>
      ) : null}
    </div>
  )
}

export default FormGroup
